import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { graphql, Link } from 'gatsby'
import { ImHome } from "react-icons/im"
import { IoRocketOutline } from "react-icons/io5"
import Social from "../components/Social"
import SubscribeForm from "../components/SubscribeForm"
import SDArticle from "../components/StructuredData/SDArticle"
import GoogleMap from "../components/GoogleMap"
import comicYellowBG from "../images/comic-yellow-background-small.svg"
import MoreLocations from "../components/MoreLocations"
 
const LocationPage = ({data, location}) => {
    const loc = data.locdata
    return (
        <>
            <Layout location={location}>
                <SDArticle url={location.href} title={loc.frontmatter.title} image={loc.frontmatter.featureimage.publicURL} datepub={loc.frontmatter.sddate} datemod={loc.frontmatter.sddatemod} />
                <SEO title={loc.frontmatter.seotitle} description={loc.frontmatter.seometadesc} image={loc.frontmatter.featureimage.publicURL} />{/* title, description, image, article */}
                <PageHero heading={loc.frontmatter.title} />
                <article className="container mx-auto max-w-7xl text-center pt-4">
                    <div className="px-8 pt-4">
                        <ul className="text-left font-mono text-xs md:text-base">
                          <li className="inline-block border-black border-r-2">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-2">Home</span>
                            </Link>
                          </li>
                          <li className="inline-block border-black border-r-2">
                            <Link to="/locations/">
                                <span className="px-2">Locations</span> 
                            </Link>
                          </li>
                          <li className="inline-block">
                            <span className="px-2">{loc.frontmatter.title}</span>
                          </li>
                        </ul>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row print-section">
                        <div className="lg:w-2/3">
                            <div className="p-6 cmscontent" dangerouslySetInnerHTML={{ __html: loc.html}} />
                            <GoogleMap zoom={loc.frontmatter.mapzoom} maptitle={loc.frontmatter.maptitle} />
                            <div className="noprint-section comic">
                                <div className="w-full">
                                    <Link to={loc.frontmatter.quoteboxlink}>
                                        <div className="panel p-3 pulserev" style={{backgroundImage: "url(" + comicYellowBG + ")", backgroundSize: "cover", overflow: "hidden",}}>
                                            <p className="text top-left text-lg md:text-xl z-20">
                                                {loc.frontmatter.quoteboxtoptext}
                                            </p>
                                            <p className="speech mt-8 mx-1 text-3xl text-center" style={{ fontFamily: "Bangers, cursive" }}>
                                                <span className="uppercase text-2xl sm:text-3xl md:text-5xl tracking-wider text-gray-600">
                                                    {loc.frontmatter.quoteboxtext}
                                                </span>
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="hidden lg:inline-block ">
                                <MoreLocations curpost={loc.fields.slug} />
                            </div>
                        </div>
                        <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                            <div className="lg:sticky top-24">
                                <div className="text-xs md:text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                                    <div className="inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                                    <p>Updated: {loc.frontmatter.datemod}</p>
                                </div>
                                <p className="text-xs hidden lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                                <p className="text-xs hidden lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                                <h3 className="hidden lg:inline-block pulse heading-box-text2 text-2xl pt-4 mx-8">
                                    <span>it's good to share!</span>
                                </h3>
                                <Social location={location} notitle={true} />
                                <div className="hidden lg:inline-block">
                                    <SubscribeForm formid="subscribe-form-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center lg:hidden">
                          <h3 className="pulse heading-box-text2 text-2xl pt-6 mx-8">
                            <span>it's good to share!</span>
                          </h3>
                            <Social location={location} notitle={true} />
                            <SubscribeForm formid="subscribe-form-2" />
                            <MoreLocations curpost={loc.fields.slug} />
                    </div>
                </article>
            </Layout>
        </>
    )
}

export default LocationPage

export const query = graphql`
query($slug: String!) {
    locdata: markdownRemark(fields: { slug: { eq: $slug }}) {
      html
      frontmatter {
          title
          sddate: date
          date(formatString: "DD MMMM, YYYY")
          sddatemod: datemod
          datemod(formatString: "DD MMMM, YYYY")
          description
          quoteboxtoptext
          quoteboxtext
          quoteboxlink
          mapzoom
          maptitle
          seotitle
          seometadesc
          featureimagealt
          featureimage {
            publicURL
          }
      }
      fields {
        slug
      }
    }
  }
`