import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const MoreLocations = ({curpost}) => {
    const {locdata} = useStaticQuery(graphql`
        query {
            locdata: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(locations)/.*[.]md$/"}}, 
                sort: {fields: [frontmatter___date], order: DESC}, 
                limit: 10
            ) {
            edges {
                node {
                fields {
                    slug
                }
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    title
                    description
                }
                }
            }
            }
        }
        `
    )
    const locsfull = locdata
    return (
        <>
                <section className="container mx-auto text-center pt-8">
                    <div className="text-left m-6">
                        {locsfull.edges.map(({node}) => {
                            const { frontmatter, fields } = node
                            const currentpost = (fields.slug === curpost)
                            return (
                                <div key={node.id} className="w-full hover:text-pink-700">
                                {!currentpost &&(
                                    <Link to={fields.slug} itemProp='url'>
                                        <h6 className="text-purple-900 text-lg sm:text-xl md:text-2xl text-left py-1 font-mono"><span>{frontmatter.title}</span></h6>
                                        <p className="text-lg sm:text-xl font-light text-left py-3">
                                            {frontmatter.description}
                                        </p>
                                    </Link>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </section>
        </>
    )
}

export default MoreLocations